import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"10","xl":"3","lg":"3","md":"4","sm":"6"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.Login.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":"Username","name":"username","type":"text","outlined":"","value":"","autocomplete":"username","rules":[_vm.rules.required, _vm.rules.minUsername]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{staticClass:"input-group--focused",attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required, _vm.rules.minPassword],"type":_vm.showPassword ? 'text' : 'password',"name":"password","label":"Password","hint":"At least 8 characters","value":"","outlined":"","autocomplete":"current-password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VSpacer),_c(VBtn,{attrs:{"color":"success","loading":_vm.loading,"block":true,"disabled":!_vm.valid,"type":"submit"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-login-variant")]),_vm._v(" Login ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }