import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import Mutate from 'vuetify/lib/directives/mutate';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{directives:[{def: Mutate,name:"mutate",rawName:"v-mutate",value:(_vm.OnMutate),expression:"OnMutate"}],ref:"toolbar",attrs:{"app":"","color":"primary","dark":"","dense":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_vm._t("extension")]},proxy:true}],null,true)},[_c(VBtn,{attrs:{"icon":"","to":{ path: '/home' }}},[_c(VIcon,[_vm._v("mdi-home")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.routeTitle))]),_c(VSpacer),_vm._t("menu")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }