import { render, staticRenderFns } from "./NavShell.vue?vue&type=template&id=0a7de958&scoped=true&"
import script from "./NavShell.vue?vue&type=script&lang=js&"
export * from "./NavShell.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7de958",
  null
  
)

export default component.exports