<template>
  <v-data-table
    dense
    fixed-header
    :headers="headers"
    :items="items"
    hide-default-footer
    disable-pagination
    height="calc(100vh - var(--headerHeight))"
    style="margin-top: var(--headerHeight);"
  >
    <!-- <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template> -->
  </v-data-table>
</template>

<script>
import router from "../router";
export default {
  data() {
    return {
      search: "",
      items: []
    };
  },
  computed: {
    headers() {
      return [
        { text: "Код", value: "Kod" },
        { text: "Наименование", value: "Ime", divider: true },
        { text: "Количество", value: "k1", align: "end" },
        { text: "Брой", value: "Broj", align: "end" },
        { text: "Ед. Цена", value: "ed", align: "end" }
        //{ text: "", value: "s", align: "end" },
      ];
    }
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.search(new RegExp(search, "i")) !== -1
        /*
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
          */
      );
    }
  },
  mounted: function() {
    let result = this.$store.getters.searchResult;
    if (!result) {
      router.push("/");
    } else {
      let data = result.data;
      this.items = data;
    }
  }
};
</script>

<style scoped></style>
