<template>
  <v-container
    class="fill-height"
    fluid
    style="margin-top: var(--headerHeight);"
  >
    <v-row justify="center">
      <v-col cols="12" lg="4" md="6" sm="8">
        <v-form v-model="valid" @submit.prevent="submitContragent">
          <v-autocomplete
            v-model="searchContragent"
            value=""
            placeholder="име на контрагент"
            clearable
            :items="contragents"
            item-text="name"
            item-value="id"
            @change="
              () => {
                if (!searchContragent) lastOrders = [];
              }
            "
          >
          </v-autocomplete>
          <v-layout row justify-center class="pt-4">
            <v-btn
              type="submit"
              x-large
              color="primary"
              :disabled="!searchContragent"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
          </v-layout>
        </v-form>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="lastOrders && lastOrders.length > 0">
      <v-col cols="12" lg="4" md="6" sm="8">
        <v-list>
          <v-subheader
            >Последни
            {{ lastOrders ? lastOrders.length : 0 }} поръчки</v-subheader
          >
          <v-list-item-group
            v-model="selectedOrder"
            color="primary"
            @change="orderSelected"
          >
            <v-list-item v-for="item in lastOrders" :key="item.iddoc">
              <v-list-item-content>
                <v-list-item-title
                  v-text="fmtDate(item.iddat)"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title v-text="item.iddoc"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!searchContragent">
      <v-col cols="12" lg="4" md="6" sm="8">
        <v-form v-model="valid" @submit.prevent="submit">
          <v-text-field
            v-model="searchOrder"
            value=""
            :rules="[rules.required, rules.min]"
            placeholder="номер на поръчка"
            clearable
          >
          </v-text-field>
          <v-layout row justify-center class="pt-4">
            <v-btn type="submit" x-large color="primary" :disabled="!valid"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
          </v-layout>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../services/api";
import moment from "moment";

export default {
  data() {
    return {
      name: "SearchSalesOrder",
      valid: false,
      row: null,
      toggle: 0,
      // searchOrder: "",
      // searchContragent: "",
      // lastOrders: [],
      // contragents: [],
      field: 0,
      selectedOrder: null,
      rules: {
        required: value => !!value || "Required",
        min: value => (value && value.length >= 1) || "Min 3 characters"
      }
    };
  },
  computed: {
    searchOrder: {
      get() {
        return this.$store.state.searchOrder;
      },
      set(val) {
        this.$store.commit("updateSearchOrder", val);
      }
    },
    searchContragent: {
      get() {
        return this.$store.state.searchContragent;
      },
      set(val) {
        this.$store.commit("updateSearchContragent", val);
      }
    },
    lastOrders: {
      get() {
        return this.$store.state.lastOrders;
      },
      set(val) {
        this.$store.commit("updateLastOrders", val);
      }
    },
    contragents: {
      get() {
        return this.$store.state.contragents;
      },
      set(val) {
        this.$store.commit("updateContragents", val);
      }
    },
  },
  methods: {
    submit() {
      this.$store.dispatch("searchSalesOrder", `/${this.searchOrder}`);
    },
    async submitContragent() {
      // console.info(this.searchContragent);
      let result = await api.get(
        `search/sales/lastorders/${this.searchContragent}`
      );
      this.lastOrders = result.data.data;
    },
    fmtDate(date) {
      let fmt = moment(date).format("D.M.YYYY");
      return fmt;
    },
    orderSelected(index) {
      // console.info(index);
      let iddoc = this.lastOrders[index].iddoc;
      console.log(iddoc);
      this.$store.dispatch("searchSalesOrder", `/${iddoc}`);
    }
  },
  async mounted() {
    if (this.contragents && this.contragents.length == 0) {
      console.log(this.contragents.length);
      let result = await api.get(`repository/customers`);
      this.contragents = result.data.data;
    }
  }
};
</script>

<style scoped></style>
