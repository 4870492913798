<template>
  <v-container
    class="fill-height"
    fluid
    style="margin-top: var(--headerHeight);"
  >
    <v-row justify="center">
      <v-col cols="10" lg="4" md="6" sm="8">
        <v-form v-model="valid" @submit.prevent="submit">
          <v-layout row justify-center class="pt-1">
            <v-btn-toggle v-model="field" group color="primary">
              <v-btn>по име</v-btn>
              <v-btn>по код</v-btn>
            </v-btn-toggle>
          </v-layout>
          <v-layout row justify-center>
            <v-btn-toggle v-model="toggle" group color="primary">
              <v-btn><v-icon>mdi-contain-start</v-icon></v-btn>
              <v-btn><v-icon>mdi-contain</v-icon></v-btn>
              <v-btn><v-icon>mdi-contain-end</v-icon></v-btn>
            </v-btn-toggle>
          </v-layout>
          <v-layout row justify-center>
            <v-text-field
              v-model="searchString"
              value=""
              :rules="[rules.required, rules.min]"
              placeholder="търсене"
              class="pt-1 pb-1"
            >
            </v-text-field>
          </v-layout>
          <v-layout row justify-center class="pt-1">
            <v-btn type="submit" x-large color="primary" :disabled="!valid"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
          </v-layout>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SearchStock",
  data() {
    return {
      valid: false,
      row: null,
      toggle: 0,
      searchString: "",
      field: 0,
      rules: {
        required: value => !!value || "Required",
        min: value => value.length >= 1 || "Min 3 characters"
      }
    };
  },
  // computed: {
  //   loading() {
  //     return this.$store.state.loading;
  //   }
  // },
  methods: {
    submit() {
      this.$store.dispatch(
        "searchStock",
        `/${this.searchString}/${this.toggle}/${this.field}`
      );
      // this.$store.dispatch("search", {
      //   SearchString: this.searchString,
      //   Contain: this.toggle,
      //   Searchfield: this.field
      // });
    }
  }
};
</script>

<style scoped></style>
