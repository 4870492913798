<template>
  <div>
    <v-app-bar app color="primary" dark dense>
      <v-btn icon :to="{ path: '/home' }">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ routeTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data: () => ({
    searchDrawer: false,
    group: null
  }),
  computed: {
    isLoggedIn: function() {
      //console.log(`NavBar isLoggedIn: ${this.$store.getters.isLoggedIn}`);
      return this.$store.state.loggedIn;
    },
    routeTitle: function() {
      if ("meta" in this.$route && "title" in this.$route.meta)
        return this.$route.meta.title;
      else return "";
    },
    routeName: function() {
      return this.$route.name;
    },
  },

  methods: {
    logout: function() {
      this.$store.dispatch("logout");
    },
    title: function() {
      this.$store.commit("SEARCH");
    }
  }
};
</script>

<style lang="scss" scoped></style>
