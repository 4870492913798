import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",staticStyle:{"margin-top":"var(--headerHeight)"},attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6","sm":"8"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.submitContragent.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VAutocomplete,{attrs:{"value":"","placeholder":"име на контрагент","clearable":"","items":_vm.contragents,"item-text":"name","item-value":"id"},on:{"change":function () {
              if (!_vm.searchContragent) { _vm.lastOrders = []; }
            }},model:{value:(_vm.searchContragent),callback:function ($$v) {_vm.searchContragent=$$v},expression:"searchContragent"}}),_c(VLayout,{staticClass:"pt-4",attrs:{"row":"","justify-center":""}},[_c(VBtn,{attrs:{"type":"submit","x-large":"","color":"primary","disabled":!_vm.searchContragent}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1),(_vm.lastOrders && _vm.lastOrders.length > 0)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6","sm":"8"}},[_c(VList,[_c(VSubheader,[_vm._v("Последни "+_vm._s(_vm.lastOrders ? _vm.lastOrders.length : 0)+" поръчки")]),_c(VListItemGroup,{attrs:{"color":"primary"},on:{"change":_vm.orderSelected},model:{value:(_vm.selectedOrder),callback:function ($$v) {_vm.selectedOrder=$$v},expression:"selectedOrder"}},_vm._l((_vm.lastOrders),function(item){return _c(VListItem,{key:item.iddoc},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.fmtDate(item.iddat))}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.iddoc)}})],1)],1)}),1)],1)],1)],1):_vm._e(),(!_vm.searchContragent)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6","sm":"8"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"value":"","rules":[_vm.rules.required, _vm.rules.min],"placeholder":"номер на поръчка","clearable":""},model:{value:(_vm.searchOrder),callback:function ($$v) {_vm.searchOrder=$$v},expression:"searchOrder"}}),_c(VLayout,{staticClass:"pt-4",attrs:{"row":"","justify-center":""}},[_c(VBtn,{attrs:{"type":"submit","x-large":"","color":"primary","disabled":!_vm.valid}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }