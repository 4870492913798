<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="10" xl="3" lg="3" md="4" sm="6">
        <v-form v-model="valid" @submit.prevent="Login">
          <v-text-field
            v-model="username"
            label="Username"
            name="username"
            type="text"
            outlined
            value=""
            autocomplete="username"
            :rules="[rules.required, rules.minUsername]"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.minPassword]"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="Password"
            hint="At least 8 characters"
            value=""
            class="input-group--focused"
            outlined
            autocomplete="current-password"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="loading"
            :block="true"
            :disabled="!valid"
            type="submit"
          >
            <v-icon left>mdi-login-variant</v-icon>
            Login
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../services/api";
export default {
  name: "login",
  data() {
    return {
      valid: false,
      showPassword: false,
      loading: false,
      username: "",
      password: "",
      status: null,
      rules: {
        required: value => !!value || "Required.",
        minPassword: v => v.length >= 3 || "Min 8 characters",
        minUsername: v => v.length >= 5 || "Min 5 characters"
      }
    };
  },
  // computed: {
  //   loading() {
  //     return this.$store.state.loading;
  //   }
  // },
  methods: {
    // login() {
    //   this.$store
    //     .dispatch("login", {
    //       username: this.username,
    //       password: this.password
    //     })
    //     .then(() => {
    //       //console.log(`Login: ${localStorage.getItem('user')}`);
    //       this.$router.push({ name: "home" });
    //     })
    //     .catch(err => {
    //       this.status = err.response?.status ?? null;
    //     });
    // },
    Login() {
      // this.loading = true;
      api
        .post("auth/login", {
          Username: this.username,
          Password: this.password
        })
        .then(() => {
          //console.log(response)
          this.$store.commit("SET_USER_DATA");
          this.$router.push("/");
          // this.$router.push("/");
          //let accessToken = response.data.data?.access_token;
          //let refreshToken = response.data.data?.refresh_token;
          //console.log(accessToken +" " +refreshToken)
          //if (!accessToken || !refreshToken) return;
          //localStorage.setItem("token", accessToken);
          //localStorage.setItem("refresh_token", refreshToken);
          // if (this.$route.params.nextUrl != null) {
          //   this.$router.push(this.$route.params.nextUrl);
          // } else {
          //   this.$router.push("/");
          // }
        })
        .catch(error => {
          let message = error.response?.data?.message || error.message;
          this.status = message;
          // this.$store.dispatch("snackbar", {
          //   text: message,
          //   timeout: 5000,
          //   color: "error"
          // });
        });
      // .then(() => (this.loading = false));
    }
  }
};
</script>
