<template>
  <v-data-table
    v-if="items"
    dense
    fixed-header
    :headers="headers"
    :items="items"
    hide-default-footer
    disable-pagination
    height="calc(100vh - var(--headerHeight))"
    style="margin-top: var(--headerHeight);"
  >
    <template v-slot:item.Въведено="{ item }">
      {{ fmtDateTime(item.Въведено) }}
    </template>
    <template v-slot:item.ДатаДоставчик="{ item }">
      {{ fmtDate(item.ДатаДоставчик) }}
    </template>
  </v-data-table>
</template>

<script>
import api from "../services/api";
import moment from "moment";
export default {
  data() {
    return {
      search: "",
      items: null,
      loading: false
    };
  },
  computed: {
    headers() {
      return [
        { text: "Потребител", value: "Потребител", divider: true },
        { text: "Въведено", value: "Въведено", divider: true },
        { text: "Тип", value: "Тип", divider: true },
        { text: "Контргент|Склад", value: "Контргент|Склад", divider: true },
        {
          text: "Вид материал-бр|кг",
          value: "Вид материал-бр|кг",
          divider: true
        },
        { text: "Забележка", value: "Забележка", divider: true },
        { text: "Коментар", value: "Коментар", divider: true },
        { text: "Статус", value: "Статус", divider: true },
        { text: "Доставчик", value: "Доставчик", divider: true },
        { text: "Дата на доставка", value: "ДатаДоставчик", divider: true }
        //{ text: "", value: "s", align: "end" },
      ];
    }
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.search(new RegExp(search, "i")) !== -1
        /*
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
          */
      );
    },
    fmtDateTime(date) {
      let fmt = moment(date).format("D.M.YYYY H:mm:ss");
      return fmt;
    },
    fmtDate(date) {
      let fmt = moment(date).format("D.M.YYYY");
      return fmt;
    }
  },
  async mounted() {
    let result = await api.get(`report/m16`);
    this.items = result.data.data;
  }
};
</script>

<style scoped></style>
