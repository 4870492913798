<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-navigation-drawer floating permanent>
        <v-list nav>
          <v-list-item-group v-model="group">
            <v-list-item :to="{ path: '/task/stock/search' }">
              <v-list-item-icon>
                <v-icon>mdi-warehouse</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Наличности</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/task/sales/order/search' }">
              <v-list-item-icon>
                <v-icon>mdi-shopping-search</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Поръчки продажби</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/task/reportm16/result' }">
              <v-list-item-icon>
                <v-icon>mdi-briefcase-search</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Справка</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-row>
  </v-container>
</template>

<script>
//import func from '../../vue-temp/vue-editor-bridge';
export default {
  name: "navbar",
  data: () => ({
    searchDrawer: false,
    group: null
  }),
  computed: {
    isLoggedIn: function() {
      //console.log(`NavBar isLoggedIn: ${this.$store.getters.isLoggedIn}`);
      return this.$store.state.loggedIn;
    },
    routeTitle: function() {
      if ("meta" in this.$route && "title" in this.$route.meta)
        return this.$route.meta.title;
      else return "";
    }
    // loading: function() {
    //   return this.$store.state.loading;
    // }
  },

  methods: {
    logout: function() {
      this.$store.dispatch("logout");
    },
    // reportM16: function() {
    //   this.$store.dispatch("reportM16");
    // },
    title: function() {
      this.$store.commit("SEARCH");
    }
  },
  mounted() {
    this.$store.dispatch("clearSearchOrders");
  }
};
</script>

<style lang="scss" scoped></style>
