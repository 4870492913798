import axios from "axios";
import router from "../router";
import store from "../store";

var api = axios.create();
api.defaults.baseURL = process.env.VUE_APP_URL;
api.defaults.timeout = 60000;
api.defaults.withCredentials = true;
api.defaults.headers.common["X-From"] = "";


api.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    //console.log(config);
    // config.withCredentials = true;
    store.commit("LOAD_START");
    return config;
  },
  function(error) {
    // Do something with request error
    console.log(error);
    // console.log(error.response);
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  function(response) {
    // Do something with response data
    store.commit("LOAD_END");
    return response;
  },
  function(error) {
    store.commit("LOAD_END");
    //  console.log(error);
    // Do something with response error
    if (error.response) {
      // console.log(error.response);
      store.dispatch("myalert", error.response);
      if (error.response.status === 401) {
        //console.log(typeof )
        store.commit("LOGOUT");
        // console.log(router.currentRoute.path);
        if (router.currentRoute.path != "/login") {
          // console.log("push /login");
          router.push("/login");
        }
      }
    } else {
      // console.log(error.message);
      store.dispatch("myalert", error);
    }
    return Promise.reject(error);
  }
);

export default api;

// export default axios.create({
//   baseURL: "https://api.metal-m.bg/api"
//   //baseURL: "https://localhost:44371/api"
// });
