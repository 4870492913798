<template>
  <v-app>
    <Transition :css="false">
      <router-view />
    </Transition>
    <SnackbarStatus></SnackbarStatus>
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      An update is available
      <v-btn text @click="refreshApp">Update</v-btn>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import SnackbarStatus from "./components/SnackbarStatus.vue";
import update from "./mixins/update";
import store from "./store";

export default {
  name: "App",

  components: {
    SnackbarStatus
  },

  data: () => ({
    drawer: false,
    group: null
  }),
  computed: {
    overlay() {
      return store.state.loading;
    }
  },
  mixins: [update]
};
</script>
<style sc></style>
