import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VNavigationDrawer,{attrs:{"floating":"","permanent":""}},[_c(VList,{attrs:{"nav":""}},[_c(VListItemGroup,{model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c(VListItem,{attrs:{"to":{ path: '/task/stock/search' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-warehouse")])],1),_c(VListItemTitle,[_vm._v("Наличности")])],1),_c(VListItem,{attrs:{"to":{ path: '/task/sales/order/search' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-shopping-search")])],1),_c(VListItemTitle,[_vm._v("Поръчки продажби")])],1),_c(VListItem,{attrs:{"to":{ path: '/task/reportm16/result' }}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-briefcase-search")])],1),_c(VListItemTitle,[_vm._v("Справка")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }