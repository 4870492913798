<template>
  <div>
    <router-view name="navbar" ref="toolbar" v-mutate="OnMutate" />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "NavShell",
  data() {
    return {
      greeting: "Hello World!"
    };
  },
  methods: {
    OnMutate() {
      let height = 0;
      // console.log(this.$refs);
      const toolbar = this.$refs.toolbar;
      if (toolbar) {
        // console.log(toolbar);
        height = `${toolbar.$children[0].$el.offsetHeight}px`;
      }
      document.documentElement.style.setProperty("--headerHeight", height);
    }
  }
};
</script>
<style scoped></style>
