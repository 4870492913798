import Vue from "vue";
import VueRouter from "vue-router";
import MainMenu from "../components/MainMenu.vue";
import NavShell from "../components/NavShell.vue";
import NavBar from "../components/NavBar.vue";
import SearchStock from "../views/SearchStock.vue";
import Login from "../views/Login.vue";
import ResultStock from "../views/SearchStockResult.vue";
import ReportM16 from "../views/ReportM16.vue";
import SearchSalesOrder from "../views/SearchSalesOrder.vue";
import ResultSalesOrder from "../views/ResultSalesOrder.vue";

Vue.use(VueRouter);

const routes2 = [
  // { path: "/", redirect: "/login" },
  {
    path: "/home",
    component: MainMenu
  },
  {
    path: "/task",
    component: { render: c => c("router-view") },
    children: [
      {
        path: "stock",
        component: NavShell,
        children: [
          {
            path: "search",
            components: { default: SearchStock, navbar: NavBar },
            alias: "",
            meta: { title: "Наличности" }
          },
          {
            path: "result",
            components: { default: ResultStock, navbar: NavBar },
            meta: { title: "Наличности" }
          }
        ]
      },
      {
        path: "sales/order",
        component: NavShell,
        children: [
          {
            path: "search",
            alias: "",
            components: {
              default: SearchSalesOrder,
              navbar: NavBar
            },
            meta: { title: "Поръчки продажби" }
          },
          {
            path: "result",
            components: {
              default: ResultSalesOrder,
              navbar: null
            },
            meta: { title: "Поръчки продажби" }
          }
        ]
      },
      {
        path: "reportm16",
        component: NavShell,
        children: [
          {
            path: "result",
            alias: "",
            components: { default: ReportM16, navbar: NavBar },
            meta: { title: "Справка М16" }
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "*",
    redirect: "/home"
  }
];

const router = new VueRouter({
  mode: "history",
  routes: routes2
});

export default router;

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem("token");
  if (authRequired && !loggedIn) {
    if (from.path != "/login") return next("/login");
    else return next(false);
  } else {
    next();
  }
});
