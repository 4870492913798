<template>
  <div>
    <v-app-bar app color="primary" dark dense ref="toolbar" v-mutate="OnMutate">
      <v-btn icon :to="{ path: '/home' }">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ routeTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <slot name="menu" />
      <template v-slot:extension>
        <slot name="extension" />
      </template>
    </v-app-bar>
  </div>
</template>

<script>
//import func from '../../vue-temp/vue-editor-bridge';
export default {
  name: "navbar",
  data: () => ({
    searchDrawer: false,
    group: null
  }),
  computed: {
    isLoggedIn: function() {
      //console.log(`NavBar isLoggedIn: ${this.$store.getters.isLoggedIn}`);
      return this.$store.state.loggedIn;
    },
    routeTitle: function() {
      if ("meta" in this.$route && "title" in this.$route.meta)
        return this.$route.meta.title;
      else return "";
    },
    routeName: function() {
      return this.$route.name;
    }
    // loading: function() {
    //   return this.$store.state.loading;
    // }
  },

  methods: {
    logout: function() {
      this.$store.dispatch("logout");
    },
    // reportM16: function() {
    //   this.$store.dispatch("reportM16");
    // },
    title: function() {
      this.$store.commit("SEARCH");
    },
    OnMutate() {
      let height = 0;
      // console.log(this.$refs);
      const toolbar = this.$refs.toolbar;
      if (toolbar) {
        // console.log(toolbar);
        height = `${toolbar.$el.offsetHeight}px`;
      }
      document.documentElement.style.setProperty("--headerHeight", height);
    }
  }
};
</script>

<style scoped></style>
