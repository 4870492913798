<template>
  <v-snackbar
    v-model="snack.visible"
    :color="snack.color"
    :timeout="snack.timeout"
  >
    {{ snack.text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
//import { VBtn,VSnackbar} from "vuetify/lib";

export default {
  name: "SnackbarStatus",
  //  components: { VBtn, VSnackbar },
  computed: mapState({
    snack: "SnackStatus"
  })
};
</script>
