<template>
  <!-- <v-container fluid style="overflow: auto;" >
  </v-container> -->
  <div>
    <NavBarExt>
      <template v-slot:extension>
        <v-tabs grow v-model="tabs">
          <v-tab key="tab1">Поръчка</v-tab>
          <v-tab key="tab2">Съдържание</v-tab>
        </v-tabs>
      </template>
      <template v-slot:menu>
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="showAll"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>покажи всички</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </NavBarExt>
    <v-tabs-items
      justify-center
      v-model="tabs"
      style="margin-top: var(--headerHeight);"
    >
      <v-tab-item key="tab1" justify-center>
        <v-list>
          <v-list-item>
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Поръчка</v-list-item-subtitle>
              <v-list-item-title>{{ order }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Дата</v-list-item-subtitle>
              <v-list-item-title>{{ date }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Контрагент</v-list-item-subtitle>
              <v-list-item-title>{{ partner }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Телефон</v-list-item-subtitle>
              <v-list-item-title>{{ phone }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Адрес на доставка</v-list-item-subtitle>
              <v-list-item-title>{{ address }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-calendar-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Дата на доставка</v-list-item-subtitle>
              <v-list-item-title>{{ dateDelivery }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-cash-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Платено</v-list-item-subtitle>
              <v-list-item-title>{{ paid }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Сума с ДДС</v-list-item-subtitle>
              <v-list-item-title>{{ sumVAT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item key="tab2">
        <v-data-table
          dense
          disable-sort
          fixed-header
          hide-default-footer
          disable-pagination
          :headers="headers"
          :items="items"
          height="calc(100vh - var(--headerHeight))"
        >
          <template v-slot:item.DateTime="{ item }">
            {{ fmtDateTimeRow(item) }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import router from "../router";
import NavBarExt from "../components/NavBarExt.vue";
import moment from "moment";
export default {
  data() {
    return {
      search: "",
      order: "",
      partner: "",
      items: [],
      phone: "",
      address: "",
      dateDelivery: "",
      date: "",
      paid: "",
      sumVAT: 0,
      tabs: null,
      showAll: false
    };
  },
  components: {
    NavBarExt
  },
  computed: {
    headers() {
      return [
        // { text: "Контрагент", value: "Контрагент" },
        { text: "Артикул#", value: "idart", divider: true },
        // { text: "Артикул", value: "namebgl", divider: true },
        { text: "Артикул", value: "text", divider: true },
        { text: "Броя", value: "Количество2", divider: true },
        { text: "Килограми", value: "Количество1", divider: true },
        { text: "Отстъпка", value: "Отстъпка", divider: true },
        { text: "Цена", value: "БазоваЦена", divider: true },
        { text: "Сума", value: "Сума", divider: true },
        { text: "Потребител", value: "Потребител", divider: true },
        { text: "Дата", value: "DateTime", divider: true },
        {
          text: "Фактурирана",
          value: "Фактурирана",
          filter: value => {
            // console.log(value);
            if (this.showAll) return true;
            return value != 1;
          },
          divider: true
        },
        { text: "Документ Продажба", value: "Документ Продажба", divider: true }
        // { text: "Час", value: "Час", divider: true },

        // { text: "Телефон", value: "Телефон", divider: true },
        // { text: "АдресДоставка", value: "АдресДоставка", divider: true },
        // { text: "ДатаДоставка", value: "ДатаДоставка", divider: true },
        // { text: "Платено", value: "Платено", divider: true }
      ];
    }
  },
  methods: {
    fmtDateTimeRow(row) {
      // console.log(`${row.Дата}T${row.Час}`);
      let fmt = moment(`${row.Дата}T${row.Час}`).format("D.M.YYYY HH:mm");
      return fmt;
    },
    fmtDate(strDate) {
      // let dt = new Date(Date.parse(strDate));
      // console.log(strDate);
      return moment(strDate).format("D.M.YYYY");
      // return `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}`;
    },
    filterCompleted(value) {
      // console.log(`${value} | ${search} | ${item}`);
      if (this.showAll) return true;
      return value != 1;
    }
  },
  mounted: function() {
    let result = this.$store.getters.searchResult;
    if (!result) {
      router.push("/");
    } else {
      let data = result.data;
      // console.log(data);
      this.order = result.order.slice(1);
      this.items = data;
      this.partner = data[0].Контрагент;
      this.phone = data[0].Телефон;
      this.address = data[0].АдресДоставка;
      let d = data[0].ДатаДоставка;
      this.dateDelivery = d ? this.fmtDate(d) : "";
      // d = data[0].DateTime;
      // this.date = d ? this.fmtDate(d) : "";
      this.date = this.fmtDateTimeRow(data[0]);
      this.paid = data[0].Платено || "0.00";
      this.sumVAT = data[0].SumVAT;
    }
  }
};
</script>

<style scoped>
.v-list-item__title {
  text-overflow: initial !important;
  white-space: initial !important;
}
.app {
  background: lightgrey !important;
  height: 100vh;
  overflow: hidden;
}

.view {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-top {
  padding: 20px;
}

.table-container {
  display: flex;
  /* margin-top: var(--headerHeight); */
  flex-grow: 1;
  overflow: hidden;
}

.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.flex-table > div {
  width: 100%;
  overflow: auto;
}
</style>
