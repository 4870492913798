import Vue from "vue";
import Vuex from "vuex";
import router from "./router/index.js";
import api from "./services/api.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    loggedIn: !!sessionStorage.getItem("token"),
    // loggedIn: !!localStorage.getItem("token"),
    SnackStatus: {
      visible: false,
      color: "error",
      timeout: 3000,
      text: "Some text"
    },
    searchResult: null,
    searchOrder: null,
    searchContragent: null,
    appBarExtension: null,
    lastOrders: [],
    contragents: []
  },
  getters: {
    searchResult: state => state.searchResult,
    // resultM16: state => state.searchResult,
    loading: state => state.loading
  },
  mutations: {
    // LOGIN(state) {
    //   state.loggedIn = true;
    //   localStorage.setItem("token", true);
    //   console.log('isLoggedIn ' + state.isLoggedIn)
    // },
    SET_USER_DATA(state, userData) {
      // localStorage.setItem("token", userData);
      sessionStorage.setItem("token", userData);
      state.loggedIn = true;
    },
    LOGOUT() {
      // localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      // location.reload();
    },
    SNACKBAR(state, Data) {
      console.log(Data);
      state.SnackStatus.color = "error";
      if ("data" in Data && Data.data) {
        let error = Data.data;
        state.SnackStatus.color = error.type;
        state.SnackStatus.text = error.message;
      } else if ("text" in Data) {
        state.SnackStatus.text = Data.text;
      } else if ("message" in Data) {
        state.SnackStatus.text = Data.message;
      } else {
        console.log("2:" + Data);
        state.SnackStatus.text = Data.statusText;
      }
      state.SnackStatus.visible = true;
      // Object.assign(state.SnackStatus, Data);
    },
    SEARCH_DONE(state, args) {
      // state.searchResult = args.data.data;
      state.searchResult = args;
      // console.info(router.currentRoute);
      router.push(args.path);
    },
    SEARCH() {
      let path = router.currentRoute.path;
      if (path.endsWith("/result")) {
        let next = path.substring(0, path.lastIndexOf("result")) + "search";
        router.push(next);
      }
    },
    LOAD_START(state) {
      state.loading = true;
    },
    LOAD_END(state) {
      state.loading = false;
    },
    updateSearchOrder(state, value) {
      state.searchOrder = value;
    },
    updateSearchContragent(state, value) {
      state.searchContragent = value;
    },
    updateLastOrders(state, value) {
      state.lastOrders = value;
    },
    updateContragents(state, value) {
      state.contragents = value;
    }
    // M16_DONE(state, data) {
    //   state.searchResult = data.data;
    //   router.push("/reportm16");
    // }
  },
  actions: {
    // login({ commit }, credentials) {
    //   return api.post("auth/login", credentials).then(() => {
    //     commit("SET_USER_DATA", true);
    //   });
    // },
    logout({ commit }) {
      return api.post("auth/logout").then(() => {
        commit("LOGOUT");
      });
    },
    myalert({ commit }, Data) {
      commit("SNACKBAR", Data);
    },
    searchStock({ commit }, data) {
      return api.get(`search/stock${data}`).then(response => {
        commit("SEARCH_DONE", {
          path: "/task/stock/result",
          data: response.data.data
        });
      });
    },
    searchSalesOrder({ commit }, searchOrder) {
      return api.get(`search/sales/order${searchOrder}`).then(response => {
        // console.log(response);
        commit("SEARCH_DONE", {
          order: searchOrder,
          path: "/task/sales/order/result",
          data: response.data.data
        });
      });
    },
    clearSearchOrders() {
      this.state.searchOrder = "";
      this.state.searchContragent = "";
      this.state.lastOrders = [];
      this.state.contragents = [];
    }
  }
});
